<template>
  <div>
    <div class="flex justify-between my-2 items-center">
      <h2 class="text-2xl space-x-2 flex items-center">
        <span
          class="inline-block h-4 w-4 rounded-full mr-2"
          :class="{
            'bg-red-600': !loadings.all && Object.keys(period).length === 0,
            'bg-blue-400 animate-ping': loadings.toggleEnabled || (loadings.all && !Object.keys(period).length > 0),
            'bg-green-500': period.enabled,
            'bg-gray-400': !period.enabled,
          }"
          v-tooltip="loadings.all ? 'Buscando' : Object.keys(period).length > 0 ? period.enabled ? 'Activo' : 'Inactivo' : 'No encontrado'" /> Detalles del período
        <template v-if="!loadings.all && Object.keys(period).length > 0">
          {{ period.year }}-{{ period.number }}
        </template>
        <template v-else-if="!loadings.all && !Object.keys(period).length > 0">
          <span class="text-gray-400 inine-block ml-2">No encontrado</span>
        </template>
      </h2>
      <a-dropdown v-if="user.isAdmin">
        <span class="px-4 h-8 bg-white rounded-full space-x-2 items-center flex border focus:outline-none focus:ring-2 focus:ring-primary">
          Ver opciones <a-icon class="ml-2 transform rotate-90" name="more" />
        </span>
        <template #menu>
          <template v-if="loadings.all">
            <div class="flex p-1 select-none">
              <a-loader class="mr-2" /> Cargando opciones...
            </div>
          </template>
          <template v-else>
            <a-dropdown-item @click="modals.pensums = true">
              Asignar pensum
            </a-dropdown-item>
            <a-dropdown-item @click="openInscriptions('enabled_inscriptions')">
              {{ period.enabled_inscriptions | boolean('Desactivar', 'Activar') }} inscripciones
            </a-dropdown-item>
            <a-dropdown-item @click="openInscriptions('enabled_preregisters')">
              {{ period.enabled_preregisters | boolean('Desactivar', 'Activar') }} preinscripciones
            </a-dropdown-item>
            <a-dropdown-item v-if="period.id" @click="update({ enabled: !period.enabled })" :disabled="loadings.toggleEnabled">
              <div class="flex">
                <a-loader class="mr-2" v-if="loadings.toggleEnabled" />
                {{period.enabled
                ? loadings.toggleEnabled ? 'Desactivando...' : 'Desactivar'
                : loadings.toggleEnabled ? 'Activando...' : 'Activar'
                }}
              </div>
            </a-dropdown-item>
            <a-dropdown-item :to="{ name: 'create_period', query: { update: period.id } }" v-if="period.id">
              Actualizar
            </a-dropdown-item>
          </template>
        </template>
      </a-dropdown>
    </div>

    <div class="flex flex-wrap -mx-2 mb-4">
      <div class="w-full xl:w-1/4 px-2">
        <div class="rounded-2xl bg-white border border-gray-100 shadow-md">
          <h2 class="text-xl p-2">Personal</h2>
          <div class="flex flex-col divide-y">
            <section class="flex justify-between flex-wrap p-2" v-for="(value, attribute, key) in period.staff" :key="key">
              <b>{{ staff[attribute] }}</b>
              <span>{{value}}</span>
            </section>
          </div>
        </div>
      </div>
      <div class="w-full xl:w-3/4 px-2">
        <div class="bg-white rounded-2xl border border-gray-100 shadow-md">
          <h2 class="text-xl p-2">
            Datos generales
          </h2>
          <div class="flex flex-wrap -mx-2 p-2">
            <div class="w-full md:w-1/2 px-2">
              <div class="flex flex-col w-full space-y-2">
                <section class="flex justify-between flex-wrap">
                  <b>Fecha de inicio</b>
                  <span>{{ $options.filters.date(period.start_date, 'DD-MM-YYYY') }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Fecha de cierre</b>
                  <span>{{ $options.filters.date(period.end_date, 'DD-MM-YYYY') }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Fecha de primer corte</b>
                  <span>{{ $options.filters.date(period.first_cut_start_date, 'DD-MM-YYYY') }} - {{ $options.filters.date(period.first_cut_end_date, 'DD-MM-YYYY') }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Fecha de segundo corte</b>
                  <span>{{ $options.filters.date(period.second_cut_start_date, 'DD-MM-YYYY') }} - {{ $options.filters.date(period.second_cut_end_date, 'DD-MM-YYYY') }}</span>
                </section>

                <section class="flex justify-between flex-wrap">
                  <b>Inscripciones</b>
                  <span>{{ period.enabled_inscriptions ? 'Si' : 'No' }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Pre inscripciones</b>
                  <span>{{ period.enabled_preregisters ? 'Si' : 'No' }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Encuestas</b>
                  <span>{{ period.polls ? 'Si' : 'No' }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Entregas finales</b>
                  <span>{{ period.final_delivery ? 'Si' : 'No' }}</span>
                </section>
                <section v-if="period.final_delivery" class="flex justify-between flex-wrap">
                  <b>Porcentaje de evaluación de entrega final</b>
                  <span>{{ period.final_delivery_evaluation_weighting_prc }}</span>
                </section>
              </div>
              <section class="my-2">
                <b>Dias de entregas finales</b>
                <div class="flex" v-if="finalDeliveryDays.length">
                  <span class="border border-gray-100 rounded-full px-4 py-1 bg-primary text-white" v-for="(day, key) in finalDeliveryDays" :key="key">
                    {{ day }}
                </span>
                </div>
              </section>
            </div>
            <div class="w-full md:w-1/2 px-2 mt-2 xl:mt-0">
              <div class="flex flex-col w-full space-y-2">
                <section class="flex justify-between flex-wrap">
                  <b>Porcentaje de evaluación del primer corte</b>
                  <span>{{ period.first_cut_evaluation_weighting_prc }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Porcentaje de evaluación del segundo corte</b>
                  <span>{{ period.second_cut_evaluation_weighting_prc }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Mínima nota aprobatoria</b>
                  <span>{{ period.approval_minimum_note }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Mínimo de clases evaluadas por corte</b>
                  <span>{{ period.min_evaluated_lessons }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Nota de alto rendimiento</b>
                  <span>{{ period.high_performance_note }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Nota de bajo rendimiento</b>
                  <span>{{ period.low_performance_note }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Nota máxima</b>
                  <span>{{ period.max_note }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Mínimo promedio aprobatorio</b>
                  <span>{{ period.approval_minimum_avg }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Turnos disponibles</b>
                  <span>{{ scheduleShifts.map($0 => shifts[$0]).join(', ') }}</span>
                </section>
                <section class="flex justify-between flex-wrap">
                  <b>Costo</b>
                  <span>{{ `${period.semester_price_e2 / 100} USD` }}</span>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-table
      rounded
      :columns="columns"
      :source="pensums"
      @row-click="$router.push({ name: 'pensums.show', params: { id: $event.item.id } })"
      :loading="loadings.all">
      <template #toolbar(search)>
        <h2 class="text-2xl">
          Pensums
        </h2>
      </template>
    </a-table>

    <a-modal title="Asignar pensum" :show="modals.pensums" @cancel="modals.pensums = false" cancel-text="Cerrar">
      <ul class="border rounded-2xl divide-y">
        <template v-if="loadings.all">
          <li class="flex justify-center text-lg p-3 animate-bounce">
            Obteniendo pensums...
          </li>
        </template>
        <template v-else-if="!loadings.all && availablePensums.length > 0">
          <li v-for="pensum in availablePensums" :key="pensum.id" class="flex justify-between p-3">
            <p>{{ pensum.name }}</p>
            <div class="flex space-x-2">
              <router-link :to="{ name: 'pensums.show', params: { id: pensum.id } }" class="text-primary">Ver</router-link>
              <button class="text-primary focus:outline-none disabled:text-gray-400 disabled:cursor-not-allowed" @click="assign(pensum.id)" :disabled="assigning.includes(pensum.id)">
                <a-loader v-if="assigning.includes(pensum.id)" class="text-primary" />
                <template v-else>
                  Asignar
                </template>
              </button>
            </div>
          </li>
        </template>
        <template v-else>
          <li class="flex justify-center text-lg p-3">
            No hay pensums disponibles
          </li>
        </template>
      </ul>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    period: {},
    canAssignPensum: false,
    availablePensums: [],
    modals: {
      pensums: false
    },
    assigning: [],
    loadings: {
      all: false,
      toggleEnabled: false,
      togglePreinscriptions: false
    }
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user
    }),
    pensums() {
      return this.period?.pensums ?? []
    },
    columns() {
      return [
        { title: 'Nombre', key: 'name' },
        { title: 'Descripción', key: 'description' },
        { title: 'Créditos', key: 'credits' },
      ]
    },
    staff() {
      return {
        director: 'Director',
        vice_director: 'Sub director',
        executive_director: 'Director ejecutivo',
        administrative_director: 'Director de administración',
        study_control: 'Responsable de control de estudios',
        reports_signer: 'Responsable de firma'
      }
    },
    scheduleShifts() {
      return this.period?.settings?.schedule_shifts ?? []
    },
    shifts() {
      return {
        mixed: 'Mixto',
        morning: 'Mañana',
        afternoon: 'Tarde'
      }
    },
    finalDeliveryDays() {
      return this.period?.final_delivery_days ?? []
    }
  },
  methods: {
    ...mapActions({
      fetchPeriod: 'periods/show'
    }),
    moment: () => moment(),
    update(body) {
      this.$repository.periods
        .update(this.period.id, body)
        ._loading(() => this.loadings.toggleEnabled = !this.loadings.toggleEnabled)
        .then(({ data }) => {
          this.period = {
            ...this.period,
            ...data
          }
        })
    },
    openInscriptions(type) {
      this.$repository.periods
        .openInscriptions(this.$route.params.id, {
          [type]: !this.period[type]
        })
        .then(({ data }) => {
          this.period = {
            ...this.period,
            ...data
          }
        })
        ._loading(() => this.loadings.togglePreinscriptions = !this.loadings.togglePreinscriptions)
    },
    assign(pensum) {
      this.$confirm({
        message: `¿Desea Asignar este pensum a este periodo?`,
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            this.$repository.periods
              .assignPensum(this.period.id, pensum)
              .then(() => {
                this.modals.pensums = false
                this.fetch()
              })
              .catch(() => {
                this.fire()
              })
              ._loading(() => {
                if (!this.assigning.includes(pensum)) {
                  this.assigning.push(pensum)
                } else {
                  this.assigning.splice(this.assigning.indexOf(pensum), 1)
                }
              })
          }
        }
      })
    },
    fetch() {
      return Promise.all([
        this.fetchPeriod({ id: this.$route.params.id, query: { with: `pensums` } }),
        this.$repository.pensums.index({ limit: 0, with: 'periods,semesters.courses' }),
        this.$repository.periods.index({ limit: 0 })
      ])
        ._loading(() => this.loadings.all = !this.loadings.all)
        .then(([period, { data: pensums }, { data: periods }]) => {
          this.period = period
          this.canAssignPensum = period.id >= periods.find($0 => $0.enabled)?.id ?? 0
          this.availablePensums = pensums
            .filter($0 => !$0.periods.length > 0)
            .filter($0 => $0.semesters.length > 0)
            .filter($0 => $0.semesters.some($1 => $1.courses.length))
            .filter($0 => $0.version !== null)
        })
    }
  },
  mounted() {
    this.fetch()
  }
}
</script>
